.react-pdf__Page {
    border-radius: 5px;
    max-width: 100% !important;
}
.react-pdf__Page__textContent {
    border: 1px solid;
    border-radius: 5px;
    max-width: 100% !important;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    border-radius: 5px;
    max-width: 100% !important;
}

.react-pdf__Page__annotations {
    max-width: 100% !important;
}

/* For all pages */
.all-page-container {
    height: 100%;
    overflow: auto;
    max-width: 100% !important;
}

.hiddenCanvasElement {
    display: none;
}
